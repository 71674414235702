<template>
    <modal ref="modalCrearCampana" :titulo="`${editando ? 'Editar' : 'Crear'} campaña`" adicional="Guardar" no-aceptar @adicional="handleSave()">
        <div class="row mx-0 justify-center">
            <div class="col-8 px-0">
                <p class="text-general f-12 pl-3">Nombre de la campaña</p>
                <el-input v-model="nombre_campana" class="w-100" placeholder="Nombre" size="small" show-word-limit maxlength="60" />
            </div>
        </div>
        <div class="row mx-0 my-4 align-items-center justify-center">
            <div class="col-8 d-flex cr-pointer" @click="toggleDestacado()">
                <i 
                :class="`${destacado ? 'icon-star text-warning' : 'icon-star-empty text-gris'}`"
                />
                <div class="col-auto text-general">
                    Marcar como destacado
                </div>
            </div>
        </div>
        <div class="row mx-0 justify-center">
            <div class="col-8 px-0">
                <el-select v-model="cedi" placeholder="Selecciona" size="small" class="w-100" filterable @change="selectCedi()">
                    <el-option
                    v-for="item in cedisUnselected"
                    :key="item.id"
                    :label="item.nombre"
                    :value="item.id"
                    />
                </el-select>
            </div>
            <div v-for="data in cedisSelected" :key="data.id" class="col-8 d-middle my-2 px-2 border br-6" style="background-color:#F6F9FB;height:32px;">
                <span class="text-general f-14 pl-3">{{ data.nombre }}</span>
                <i class="icon-cancel text-general f-16 ml-auto cr-pointer" @click="unselectCedi(data)" />
            </div>
        </div>
    </modal>
</template>

<script>
import notificaciones from '~/services/publicidad/notificaciones'
import { mapGetters } from  'vuex'
export default {
    data(){
        return{
            nombre_campana: '',
            cedi: '',
            cedis: [],
            destacado: false,
            editando: false,
        }
    },
    computed: {
        ...mapGetters({
            carpeta: 'publicidad/notificaciones/carpeta'
        }),
        cedisSelected(){
            return this.cedis.filter(el => el.selected)
        },
        cedisUnselected(){
            return this.cedis.filter(el => !el.selected)
        },

    },
    methods: {
        async toggle(){
            this.editando = false
            this.nombre_campana = ''
            await this.listarCedis()
            this.$refs.modalCrearCampana.toggle();
        },
        async editar(model){
            this.editando = true
            await this.listarCedis()
            let newModel = _.cloneDeep(model)
            this.nombre_campana = newModel.nombre_campana
            this.destacado = newModel.destacado
            this.cedis = this.cedis.map(el => ({...el, selected: newModel.cedis.includes(el.id)}))
            this.$refs.modalCrearCampana.toggle();

        },
        toggleDestacado(){
            this.destacado = !this.destacado
        },
        async listarCedis(){
            try {
                const { data } = await notificaciones.listarCedis();
                this.cedis = data.cedis.map(el => ({...el, selected: false}))
            } catch (error){
                this.error_catch(error)
            }
        },
        selectCedi(){
            let cedi = this.cedis.find(el => el.id == this.cedi)
            cedi.selected = true
            this.cedi = ''
        },
        unselectCedi(cedi){
            let find = this.cedis.find(el => el.id == cedi.id)
            find.selected = false
        },
        handleSave(){
            if (this.editando){
                this.editarCampana()
            } else {
                this.crearCampana()  
            }
        },
        async editarCampana(){
            try {
                if(!this.nombre_campana) return this.notificacion('', 'El nombre es requerido', 'error')
                if(!this.cedisSelected.length) return this.notificacion('', 'Debe seleccionar al menos un cedis', 'error')

                let model = {
                    nombre: this.nombre_campana,
                    destacado: this.destacado,
                    carpeta: this.carpeta.id,
                    cedis: this.cedisSelected.map(({ id }) => id)
                }
                const { data } = await notificaciones.editarCampana(model, this.$route.params.id_campana);
                if(data.exito){
                    this.notificacion('', 'Guardado correctamente', 'success')
                    this.$refs.modalCrearCampana.toggle();
                    this.$emit('saved')
                }
            } catch (error){
                this.error_catch(error)
            }
        },
        async crearCampana(){
            try {
                if(!this.nombre_campana) return this.notificacion('', 'El nombre es requerido', 'error')
                if(!this.cedisSelected.length) return this.notificacion('', 'Debe seleccionar al menos un cedis', 'error')

                let model = {
                    nombre: this.nombre_campana,
                    destacado: this.destacado,
                    carpeta: this.carpeta.id,
                    cedis: this.cedisSelected.map(({ id }) => id)
                }

                const { data } = await notificaciones.crearCampana(model);
                if(data.exito){
                    this.notificacion('', 'Guardado correctamente', 'success')
                    this.$refs.modalCrearCampana.toggle();
                    this.$emit('saved')
                }
            } catch (error){
                this.error_catch(error)
            }
        }
    }
}
</script>